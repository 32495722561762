import Vue from "vue";
import axios from "@/plugins/axios";

// initial state
const state = () => ({
  items: [],
  item: {},
  selection: [] // selection of products depending on current problem
});

// getters
const getters = {
  items: (state) => state.items,
  item: (state) => state.item,
  selection: (state) => state.selection
};

// actions
const actions = {
  reset({ commit }) {
    commit("SET_ITEMS", []);
    commit("SET_ITEM", {});
    commit("SET_SELECTION", []);
  },

  fetch({ commit }) {
    return new Promise((success, failure) => {
      axios
        .post("/", {
          query: `
          {
            allproduit {
              entity {
                id
                title
                language
                entity_type
                accroche
                texteIntro
                avantages
                video {
                  id
                  url
                }
                lien
                references {
                  id
                  name
                  logo {
                    url
                  }
                }
                gammeProduit {
                  id
                  name
                }
                mediacollection {
                  id
                  url
                }
                avantage_media {
                  id
                  file
                }
                avantage_gamme {
                  titre
                  description
                  language
                  image {
                    file
                  }
                }
                weight
                domaines_application
                caract_tech
                documentation
              }
            }
          }
          `
        })
        .then((response) => {
          const products = [];
          if (response.data.data && response.data.data.allproduit) {
            response.data.data.allproduit.entity.forEach((product) => {
              if (product && product.language === Vue.prototype.$lang) {
                let documentation = null;
                if (product.documentation) {

                  product.documentation.forEach((doc) => {
                    const docArr = doc.split("####");
                    if ((docArr[0] === product.language && docArr[1] !== ''))
                      documentation = docArr[1];
                  });
                }

                product.documentation = documentation;
                products.push(product);
              }
            });
          }
          console.log(products);
          commit("SET_ITEMS", products);
          success(products);
        })
        .catch((error) => {
          console.log(error);
          failure(error);
        });
    });
  },

  find({ commit, state }, id) {
    const item = state.items.find(function(product) {
      if (product.id === id) return product;
    });
    commit("SET_ITEM", item || {});
  },

  selectByProblem({ commit, state }, problem) {
    const items = [];
    if (problem && problem.subProduits) {
      problem.subProduits.forEach((product) => {
        state.items.forEach((subproduct) => {
          if (product.id === subproduct.id) items.push(subproduct);
        });
      });
    }
    commit("SET_SELECTION", items);
  },

  selectByCategory({ commit, state }, category_id) {
    const items = [];
    if (category_id) {
      state.items.forEach((product) => {
        if (product.gammeProduit) {
          product.gammeProduit.forEach((productCategory) => {
            if (productCategory.id === category_id) items.push(product);
          });
        }
      });
    }
    commit("SET_SELECTION", items);
  }
};

// mutations
const mutations = {
  SET_ITEMS(state, value) {
    state.items = value;
  },
  SET_ITEM(state, value) {
    state.item = value;
  },
  SET_SELECTION(state, value) {
    state.selection = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
